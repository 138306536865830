<template>
	<!-- 薪酬带宽表 -->
	<div class="app-container">
		<div style="text-align: right; margin-bottom: 10px">
			<!-- <el-button type="primary" size="mini" icon="el-icon-setting" style="margin-left: 10px"></el-button> -->
			<el-popover placement="bottom" width="400" :offset="100" trigger="click">
				<el-tree
					:data="tableConfig"
					show-checkbox
					node-key="value"
					:default-checked-keys="checkedKeys"
					:props="defaultProps"
					:default-expand-all="true"
					:check-on-click-node="true"
					:expand-on-click-node="false"
					@check="treeCheck"
				>
				</el-tree>
				<el-button
					slot="reference"
					title="筛选显示列"
					class="vxe-button type--button is--circle"
					style="padding: 8px 8px 7px 8px; border-radius: 50%"
				>
					<i class="vxe-button--icon vxe-icon-custom-column"></i>
				</el-button>
			</el-popover>
		</div>
		<!-- table -->
		<el-table :data="tableData" border class="tableBackground" v-loading="loading" size="mini" style="width: 100%">
			<el-table-column
				v-for="(item, index) in tableColumns"
				:key="index"
				:prop="item.value"
				:label="item.label"
				show-overflow-tooltip
				align="center"
			>
				<el-table-column
					v-for="(c1Item, c1Index) in item.child"
					:key="c1Index"
					:prop="c1Item.value"
					:label="c1Item.label"
					show-overflow-tooltip
					align="center"
				>
					<el-table-column
						v-for="(c2Item, c2Index) in c1Item.child"
						:key="c2Index"
						:prop="c2Item.value"
						:label="c2Item.label"
						resizable
						align="center"
					>
					</el-table-column>
				</el-table-column>
			</el-table-column>
			<!-- <el-table-column align="right">
				<template slot="header" slot-scope="scope">
					<el-input v-model="search" size="mini" placeholder="输入关键字搜索" />
				</template>
				<template slot-scope="scope">
					<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
				</template>
			</el-table-column> -->
		</el-table>
	</div>
</template>

<script>
import { salaryRangeConfigView } from '@/api/remuneration';
import { cloneDeep } from 'loadsh';
export default {
	props: {
		activeName: {
			type: String
		}
	},
	components: {},
	watch: {
		/** activeName */
		activeName(val) {
			if (val === '3') {
				this.getListToTable();
			}
		},
		tableConfig(val) {
			this.tableColumns = val;
		}
	},
	computed: {
		checkedKeys() {
			return this.unfoldListCol(this.tableConfig);
		}
	},
	/** data */
	data() {
		return {
			// activeName: "2",
			operateShow: {
				show: false
			},
			loading: false,
			searchForm: {
				name: '',
				updateTimeBefore: '',
				updateTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			pageSize: 20,
			total: 0,

			tableData: [],
			tableConfig: [],
			tableColumns: this.tableConfig || [],
			defaultProps: {
				children: 'child',
				label: 'label',
				disabled: (data) => ['level', 'hierarchy'].includes(data.value)
			}
		};
	},
	/** mounted */
	mounted() {
		this.getListToTable();
	},
	methods: {
		/** getListToTable */
		getListToTable() {
			this.loading = true;
			salaryRangeConfigView({}).then((res) => {
				this.tableData = res.tableData;
				this.tableConfig = res.tableConfig;
				this.loading = false;
			});
		},
		/** currentPageChange */
		currentPageChange(data) {
			this.searchForm.pageNo = data;
			this.getListToTable();
		},
		/** handleSizeChange */
		handleSizeChange(data) {
			this.searchForm.pageSize = data;
			this.getListToTable();
		},
		// 嵌套数据铺平
		unfoldListCol(cols) {
			if (cols && cols.length === 0) {
				return [];
			}
			let arr = [];

			cols.forEach((item) => {
				const p = item.child ? [item.value, ...this.unfoldListCol(item.child)] : item.value;

				arr = arr.concat(p);
			});
			return arr;
		},
		// 树状勾选
		treeCheck(checkedNodes, { checkedKeys, halfCheckedKeys }) {
			function filterCols(cols) {
				let colums = cols;

				colums = colums
					.filter((item) => [...checkedKeys, ...halfCheckedKeys].includes(item.value))
					.map((item) => {
						let it = { ...item };

						if (item.child && item.child.length) {
							it = { ...item, child: filterCols(item.child) };
						}
						return it;
					});
				return colums;
			}
			const cols = cloneDeep(this.tableConfig);

			this.tableColumns = filterCols(cols);
		}
	}
};
</script>
<style lang="scss">
// .rank-table {
//   .el-table thead.is-group th {
//     background-color: #F0F8FF;
//   }
// }
</style>
<style lang="scss" scoped>
// .search_box {
//   margin: 10px 0;
// }
</style>
