<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="薪酬带宽表" name="second" v-if="permissionControl('SalaryBandwidthTable')">
				<salary-range-view :activeName="activeName" ref="salaryRangeView"></salary-range-view>
			</el-tab-pane>
			<!-- 薪酬带宽设置 -->
			<el-tab-pane label="薪酬带宽设置" name="first" v-if="permissionControl('SalaryBandwidthSettings')">
				<div class="search_box">
					<el-form :inline="true" :model="searchForm" ref="searchForm" class="demo-form-inline">
						<el-form-item class="formLabel" label="职级">
							<el-select v-model="searchForm.level" placeholder="请选择" clearable size="small">
								<el-option v-for="lv in levels" :key="lv.id" :label="lv.level" :value="lv.level"> </el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="formLabel" label="薪档">
							<el-select v-model="searchForm.payGears" placeholder="请选择" clearable size="small">
								<el-option v-for="pl in payGearList" :key="pl" :label="`${pl}档`" :value="pl"> </el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="formLabel" label="薪级">
							<el-select v-model="searchForm.payGrade" placeholder="请选择" clearable size="small">
								<el-option v-for="pg in payGradeList" :key="pg" :label="`${pg}级`" :value="pg"> </el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="getList()" size="small" style="margin-left: 5px" icon="el-icon-search">查询</el-button>
							<el-button
								type="primary"
								@click="addSalaryRangeConfig"
								size="small"
								icon="el-icon-plus"
								style="margin-left: 15px"
								v-if="permissionControlBtns(pageName, 'Add')"
								>添加</el-button
							>
						</el-form-item>
						<el-form-item class="fr" style="margin-right: 0" v-if="permissionControlBtns(pageName, 'Matching')">
							<el-button type="primary" @click="getList()" size="small" icon="el-icon-set-up" style="margin-left: 10px"
								>带宽匹配</el-button
							>
						</el-form-item>
					</el-form>
				</div>
				<el-table :data="tableData" stripe @selection-change="handleSelectionChange" v-loading="loading" header-row-class-name="tableClass">
					<!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
					<el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
					<el-table-column prop="level" align="center" label="职级"></el-table-column>
					<el-table-column prop="payGears" align="center" label="薪档" :formatter="formatPayGears"> </el-table-column>
					<el-table-column prop="payGrade" align="center" label="薪级" :formatter="formatPayGrade"> </el-table-column>
					<el-table-column prop="payScope" align="center" label="范围" min-width="120px" :formatter="formatPayScope"></el-table-column>
					<el-table-column prop="createUser" align="center" label="创建人"></el-table-column>
					<el-table-column prop="createTime" align="center" label="创建时间" min-width="120px" :formatter="formatTime"></el-table-column>
					<el-table-column prop="updateUser" align="center" label="更新人"></el-table-column>
					<el-table-column prop="updateTime" align="center" label="更新时间" min-width="120px" :formatter="formatTime"></el-table-column>
					<el-table-column label="操作" align="center" fixed="right" width="130">
						<template slot-scope="{ row }">
							<el-button type="text" @click="handleEdit(row)" v-if="permissionControlBtns(pageName, 'Edit')">编辑</el-button>
							<el-button type="text" @click="handleDelete(row)" style="color: #f56c6c" v-if="permissionControlBtns(pageName, 'Delete')"
								>删除</el-button
							>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					class="fr"
					style="margin: 20px 0"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="table.pageNo"
					:page-sizes="[20, 50, 100, 200]"
					:page-size="table.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="table.total"
				>
				</el-pagination>
			</el-tab-pane>
		</el-tabs>
		<el-dialog
			width="450px"
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="settingDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<el-form :model="formModel" label-width="110px">
				<el-form-item label="comId" style="display: none" prop="comId"></el-form-item>
				<el-form-item label="id" style="display: none" prop="id"></el-form-item>
				<el-form-item label="职级" prop="level" :rules="{ required: true, message: '职级必填', trigger: 'change' }">
					<el-select placeholder="请选择职级" style="width: 80%; margin: 0 5px 5px 0" filterable v-model="formModel.level">
						<el-option v-for="lv in levels" :key="lv.id" :label="lv.level" :value="lv.level"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="薪档" prop="payGears" :rules="{ required: true, message: '薪档必填', trigger: 'change' }">
					<el-select placeholder="请选择薪档" style="width: 80%; margin: 0 5px 5px 0" filterable v-model="formModel.payGears">
						<el-option v-for="pl in payGearList" :key="pl" :label="`${pl}档`" :value="pl"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="薪级" prop="payGrade" :rules="{ required: true, message: '薪级必填', trigger: 'change' }">
					<el-select placeholder="请选择薪级" style="width: 80%; margin: 0 5px 5px 0" filterable v-model="formModel.payGrade">
						<el-option v-for="pg in payGradeList" :key="pg" :label="`${pg}级`" :value="pg"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="最小值(元)" prop="payMin" :rules="{ required: true, message: '最小值必填', trigger: 'change' }">
					<el-input-number
						:min="1"
						:max="1000000"
						:controls="false"
						v-model="formModel.payMin"
						style="width: 80%; margin: 0 5px 5px 0"
					></el-input-number>
				</el-form-item>
				<el-form-item label="最大值(元)" prop="payMax" :rules="{ required: true, message: '最大值必填', trigger: 'change' }">
					<el-input-number
						:min="1"
						:max="1000000"
						:controls="false"
						v-model="formModel.payMax"
						style="width: 80%; margin: 0 5px 5px 0"
					></el-input-number>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="settingDialog = false">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { getLevelList } from '@/api/position';
import { saveSalaryRangeConfig, getSalaryRangeConfigList, deleteSalaryRangeConfig } from '@/api/remuneration';

import salaryRangeView from './salaryRangeView.vue';
export default {
	components: {
		salaryRangeView
	},
	data() {
		return {
			activeName: 'second',
			pageName: 'SalaryBandwidthTable',
			table: {
				total: 0,
				pageNo: 1,
				pageSize: 20
			},
			searchForm: {
				level: '',
				payGears: '', // 薪档
				payGrade: '' // 薪级
			},
			levels: [],
			payGearList: ['A', 'B', 'C', 'D', 'E', 'F'],
			payGradeList: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
			dialogTitle: '添加薪酬带宽设置',
			settingDialog: false,
			formModel: {
				comId: '',
				id: '',
				level: '',
				payGears: '',
				payGrade: '',
				payMin: '',
				payMax: ''
			},
			loading: false,
			tableData: []
		};
	},
	mounted() {
		this.getLevels();
		this.getList();
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: 'second', key: 'SalaryBandwidthTable' },
			{ name: 'first', key: 'SalaryBandwidthSettings' }
		]);
	},
	methods: {
		handleClick(e) {
			switch (e.index) {
				case '0':
					this.pageName = 'SalaryBandwidthTable';
					break;
				case '1':
					this.pageName = 'SalaryBandwidthSettings';
					break;

				default:
					break;
			}
		},
		getList() {
			this.loading = true;
			const params = this.searchForm;

			params.pageNo = this.table.pageNo;
			params.pageSize = this.table.pageSize;
			getSalaryRangeConfigList(params)
				.then((res) => {
					this.tableData = res.list;
					this.table.total = res.totalNum;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		handleSelectionChange() {},
		getLevels() {
			getLevelList({
				request: {
					pageNo: 1,
					pageSize: 50
				},
				sign: ''
			}).then((res) => {
				this.levels = res.list;
			});
		},
		handleSizeChange(val) {
			this.table.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.table.pageNo = val;
			this.getList();
		},
		formatPayScope(row) {
			// row:代表一行的数据；column：代表列；cellValue：代表当前单元格的值；index：代表当前单元格的下标
			return `${row.payMin}-${row.payMax}`;
		},
		formatPayGrade(row) {
			return `${row.payGrade}级`;
		},
		formatPayGears(row) {
			return `${row.payGears}档`;
		},
		formatTime(row, column) {
			let value = row.createTime;

			if (column.property === 'updateTime') {
				value = row.updateTime;
			}
			return this.formatTimes(value);
		},
		addSalaryRangeConfig() {
			this.dialogTitle = '添加薪酬带宽设置';
			this.formModel.id = '';
			this.settingDialog = true;
		},
		handleEdit(row) {
			this.formModel = row;
			this.dialogTitle = '修改薪酬带宽设置';
			this.settingDialog = true;
		},
		handleDelete(row) {
			console.log('删除的内容', row);
			deleteSalaryRangeConfig(row)
				.then((res) => {
					if (res._responseStatusCode === 0) {
						this.$message.success('删除成功');
					}
					console.log('删除成功的内容响应', res);
				})
				.finally(() => {
					this.getList();
				});
		},
		submitForm() {
			console.log('提交的内容', this.formModel);
			saveSalaryRangeConfig(this.formModel)
				.then((res) => {
					if (res._responseStatusCode === 0) {
						this.settingDialog = false;
						this.$message.success('保存成功');
					}
					console.log('提交的内容响应', res);
				})
				.finally(() => {
					this.getList();
				});
		}
	}
};
</script>

<style lang="scss" scoped></style>
